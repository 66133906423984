import { datadogRum } from '@datadog/browser-rum';

import { extractPreferencesFromCookiebot } from '@context/cookies';
import getRuntimeConfig from '@utils/getRuntimeConfig';

export default class DatadogRumLogger {
  private datadogEnv: string;
  private cookieBotStatistics: boolean;

  constructor() {
    const isProd = getRuntimeConfig('ENVIRONMENT_NAME') === 'aws-prod';
    const { statistics } = extractPreferencesFromCookiebot();

    this.datadogEnv = isProd ? 'prod' : 'qa';
    this.cookieBotStatistics = statistics;
  }

  init() {
    if (!this.hasConfigVariablesDefined()) {
      return;
    }

    datadogRum.init({
      applicationId: getRuntimeConfig('DATADOG_RUM_APPLICATION_ID'),
      clientToken: getRuntimeConfig('DATADOG_RUM_CLIENT_TOKEN'),
      // `site` refers to the Datadog site parameter of your organization
      // see https://docs.datadoghq.com/getting_started/site/
      site: getRuntimeConfig('DATADOG_RUM_SITE'),
      service: 'bm-store',
      env: this.datadogEnv,
      // Specify a version number to identify the deployed version of your application in Datadog
      version: getRuntimeConfig('VERSION') ? getRuntimeConfig('VERSION') : '',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 20,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      trackingConsent: this.cookieBotStatistics ? 'granted' : 'not-granted',
      defaultPrivacyLevel: 'mask',
    });
  }

  private hasConfigVariablesDefined() {
    return (
      !!getRuntimeConfig('DATADOG_RUM_APPLICATION_ID') &&
      !!getRuntimeConfig('DATADOG_RUM_CLIENT_TOKEN') &&
      !!getRuntimeConfig('DATADOG_RUM_SITE')
    );
  }
}
