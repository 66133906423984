import { useMutation } from 'react-query';

import { useHttpService } from '@hooks/useHttpService';

export const useUserUpdateCookieConsentMutation = () => {
  const httpService = useHttpService();

  return useMutation(async ({ marketingCookieConsent }) => {
    const response = await httpService.post('/user/update-cookie-consent', { marketingCookieConsent });

    return response;
  });
};
