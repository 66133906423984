import { useMemo } from 'react';

import { useContentContext } from '@context/content';

export const usePhrases = ({ name, rawObject = false }) => {
  const { phrases } = useContentContext();

  return useMemo(() => {
    return phrases
      ?.filter(p => p.full_slug.includes(`phrases/${name || ''}`))
      .reduce((acc, current) => {
        acc[current.slug] = rawObject ? current.content : current.content?.value;

        return acc;
      }, {});
  }, [name, phrases, rawObject]);
};
