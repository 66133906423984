import debugFactory from 'debug';
import getRuntimeConfig from '../../utils/getRuntimeConfig';

type LogObject = {
  log?: string;
  text?: string;
  [key: string]: unknown;
};

const logTypes = ['info', 'warn', 'error'] as const;
type LogType = typeof logTypes[number];


export default class SsrLogger {
  private isLocal = !['aws-prod', 'aws-qa', 'aws-dev'].includes(getRuntimeConfig('ENVIRONMENT_NAME'));
  private hideInfoLogDetails = getRuntimeConfig('HIDE_INFO_LOG_DETAILS');

  private context: string | null;
  private defaultParams = {
    env: getRuntimeConfig('ENVIRONMENT_NAME'),
  };

  private debugFunctions: {
    [key in LogType]: debugFactory.Debugger;
  } = {} as any;

  constructor(context?: string) {
    this.context = context;
    logTypes.forEach(
      logType => (this.debugFunctions[logType] = debugFactory(context.replace(/^bm:/, `bm:ssr:${logType}:`)))
    );
  }

  info(log: string | LogObject, params?: any) {
    this.logHelper('info', log, params, this.hideInfoLogDetails);
  }

  warn(log: string | LogObject, params?: any) {
    this.logHelper('warn', log, params);
  }

  error(log: string | LogObject, params?: any) {
    this.logHelper('error', log, params);
  }

  private logHelper(type: LogType, log: string | LogObject, params?: any, hideLogDetails?: true | undefined) {
    const message = this.getStringFromLog(log);
    const additionalInfo = (this.isLocal && hideLogDetails) || {
      severity: type,
      ...this.defaultParams,
      ...{ params, context: this.context },
      log,
    };

    if (this.isLocal) {
      this.debugFunctions[type](message, additionalInfo);
      return;
    }

    try {
      this.debugFunctions[type](`${message} ${JSON.stringify(additionalInfo)}`);
    } catch (error) {
      try {
        this.debugFunctions[type](`${message} ${this.stringifyWithCircularCheck(additionalInfo)}`);
      } catch (anotherError) {
        this.debugFunctions.error(`Problem with SSR Logger`);
      }
    }
  }

  private getStringFromLog(log: string | LogObject) {
    if (typeof log === 'string') {
      return log;
    }

    if (typeof log === 'object' && log !== null) {
      const logObject = log as LogObject;

      if (typeof logObject.message === 'string') {
        return logObject.message;
      }

      if (typeof logObject.text === 'string') {
        return logObject.text;
      }
    }

    return 'Details available in the log body';
  }

  private stringifyWithCircularCheck(obj: any) {
    const seen = new WeakSet();

    return JSON.stringify(obj, (key, value) => {
      if (Array.isArray(value)) {
        return [];
      }

      if (value !== null && typeof value === 'object') {
        if (seen.has(value)) {
          return;
        }
        seen.add(value);
      }

      return value;
    });
  }
}
