import React, { useEffect } from 'react';
import { setCookie, getCookie } from 'cookies-next';

import * as dayjs from 'dayjs';
import debugFactory from 'debug';
import SsrLogger from '@services/logger/ssrLogger';

import App from 'next/app';
import localFont from 'next/font/local';
import { Cookies as ReactCookies, CookiesProvider } from 'react-cookie';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import '/node_modules/nprogress/nprogress.css';
import getRuntimeConfig from '@utils/getRuntimeConfig';
import DatadogRumLogger from '@services/logger/rum';

import '../assets/scss/global/main.scss';
import { ToastMessage } from '@components/views/Toast';

const quicksand = localFont({
  src: [
    {
      path: '../assets/fonts/Quicksand/static/Quicksand-Bold.ttf',
      weight: '700',
    },
    {
      path: '../assets/fonts/Quicksand/static/Quicksand-Medium.ttf',
      weight: '500',
    },
    {
      path: '../assets/fonts/Quicksand/static/Quicksand-Regular.ttf',
      weight: '400',
    },
    {
      path: '../assets/fonts/Quicksand/static/Quicksand-SemiBold.ttf',
      weight: '600',
    },
  ],
  variable: '--quicksand',
});

const sen = localFont({
  src: [
    {
      path: '../assets/fonts/Sen/Sen-Bold.ttf',
      weight: '700',
    },
    {
      path: '../assets/fonts/Sen/Sen-Regular.ttf',
      weight: '400',
    },
    {
      path: '../assets/fonts/Sen/Sen-ExtraBold.ttf',
      weight: '800',
    },
  ],
  variable: '--sen',
});

const SCRIPTS_TO_LOAD = [
  '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js',
];

const ssrLogger = new SsrLogger('bm:app');

// AWIN affilate marketing tracking
function setAwc() {
  try {
    const urlParams = new URLSearchParams(window.location.search);
    const awc = urlParams.get('awc');

    if (!awc) {
      return;
    }

    const isAWinCookie = getCookie('awc');
    const expires = new Date();
    const isExpired = new Date() < expires;

    if (!isAWinCookie || !isExpired) {
      expires.setTime(expires.getTime() + 365 * 24 * 60 * 60 * 1000);
      setCookie('awc', awc, { expires, secure: true, sameSite: 'strict', domain: '.bluemovement.com' });
    }
  } catch (error) {
    ssrLogger.error(error);
  }
}

function BluemovementApp({ Component, cookies, router, pageProps }) {
  const queryClient = new QueryClient();

  useEffect(() => {
    // it's safe to use publicRuntimeConfig here as it's used in componentDidMount
    debugFactory.enable(getRuntimeConfig('BROWSER_DEBUG'));

    // eslint-disable-next-line no-console
    console.log(`
    BBBBBBBBBBBBBBBBB    MMMMMMMM               MMMMMMMM
    B::::::::::::::::B   M:::::::M             M:::::::M
    B::::::BBBBBB:::::B  M::::::::M           M::::::::M
    BB:::::B     B:::::B M:::::::::M         M:::::::::M
     B::::B     B:::::B  M::::::::::M       M::::::::::M
     B::::B     B:::::B  M:::::::::::M     M:::::::::::M
     B::::BBBBBB:::::B   M:::::::M::::M   M::::M:::::::M
     B:::::::::::::BB    M::::::M M::::M M::::M M::::::M
     B::::BBBBBB:::::B   M::::::M  M::::M::::M  M::::::M
     B::::B     B:::::B  M::::::M   M:::::::M   M::::::M
     B::::B     B:::::B  M::::::M    M:::::M    M::::::M
     B::::B     B:::::B  M::::::M     MMMMM     M::::::M
    BB:::::BBBBBB::::::B M::::::M               M::::::M
    B:::::::::::::::::B  M::::::M               M::::::M
    B::::::::::::::::B   M::::::M               M::::::M
    BBBBBBBBBBBBBBBBB    MMMMMMMM               MMMMMMMM
    `);

    const scriptDomain = getRuntimeConfig('USE_LOCAL_GTM_DOMAIN')
      ? 'https://data.bluemovement.com'
      : 'https://www.googletagmanager.com';

    if (getRuntimeConfig('GTM_ID')) {
      const scriptGtm = document.createElement('script');

      scriptGtm.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='${scriptDomain}/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${getRuntimeConfig(
        'GTM_ID'
      )}');`;
      scriptGtm.dataset.cookieconsent = 'ignore';
      scriptGtm.id = 'GTMScriptHGAS';
      document.body.appendChild(scriptGtm);
    }

    const datadogRumLogger = new DatadogRumLogger();
    datadogRumLogger.init();

    SCRIPTS_TO_LOAD.forEach(scritpUrl => {
      const scriptToLoad = document.createElement('script');
      scriptToLoad.src = scritpUrl;
      document.body.appendChild(scriptToLoad);
    });
  }, []);

  const { futureCouponCode } = pageProps?.oldPageProps || router.query;
  if (process.browser) {
    try {
      setAwc();

      const futureCouponMessageReadCookieName = `futureCouponMessageRead-${futureCouponCode}`;

      if (futureCouponCode && !cookies.get(futureCouponMessageReadCookieName)) {
        // TODO: Check this message
        // notifications.push({
        //   message: ReactDOMServer.renderToString(
        //     interpolateContent(get(pageContent, 'component.coupon.content.autoAppliedCoupon'), {
        //       couponCode: futureCouponCode,
        //     })
        //   ),
        //   type: 'success',
        // });
        cookies.set(futureCouponMessageReadCookieName, true, {
          expires: dayjs()
            .endOf('day')
            .toDate(),
        });
      }
    } catch (e) {
      ssrLogger.error(e);
    }
  }
  const isBrowser = typeof window !== 'undefined';

  return (
    <CookiesProvider cookies={isBrowser ? undefined : new ReactCookies(cookies)}>
      <QueryClientProvider client={queryClient}>
        <div id="page-content" className={`${quicksand.variable} ${sen.variable}`} style={quicksand.style}>
          <Component {...pageProps} />
        </div>
        <ReactQueryDevtools />
      </QueryClientProvider>

      <ToastMessage />
    </CookiesProvider>
  );
}

BluemovementApp.getInitialProps = async context => {
  const appProps = await App.getInitialProps(context);

  return {
    ...appProps,
    cookies: context.ctx.req?.headers?.cookie,
  };
};

export function reportWebVitals(metrics) {
  // eslint-disable-next-line no-console
  console.log(metrics);
}

export default BluemovementApp;
