import { useRouter } from 'next/router';

import { useContentContext } from '@context/content';

import { splitLocale } from '../services/locale-config';

export const useLocale = () => {
  const { locale, country: contextCountry, language } = useContentContext() ?? {};
  let country = contextCountry;

  const router = useRouter();
  if (router?.query?.locale && !country) {
    const { country: routerCountry } = splitLocale(router.query.locale);
    country = routerCountry;
  }

  return { locale, country, language };
};
