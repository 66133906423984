import { createContext, useEffect } from 'react';

import Logger from '@services/logger';
import getRuntimeConfig from '@utils/getRuntimeConfig';

export const LoggerContext = createContext();

export const useLogger = ({ context }) => {
  const logger = new Logger(context);

  useEffect(() => {
    if (typeof DD_LOGS === 'undefined') {
      return;
    }

    const globalContext = DD_LOGS.getGlobalContext();

    if (globalContext.env !== getRuntimeConfig('ENVIRONMENT_NAME')) {
      logger.init();
    }
  }, []);

  return { logger };
};
