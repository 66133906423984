import { Country, Language } from 'types/common.types';

const { CategoryPathsConfig } = require('./category');
const { Paths } = require('./paths');
const { PathsConfig } = require('./paths-config');
const { ClusterPathsConfig } = require('./cluster');

export const buildPathPrefix = ({ country, language }) => `${country}-${language}`;

export const buildPathForLocale = ({ pageId, locale }) => {
  const configs = { ...PathsConfig, ...ClusterPathsConfig };
  const localePath = configs?.[pageId]?.paths?.[locale] || '';

  return `/${locale}${localePath !== '/' ? localePath : ''}`;
};

// pass locale or (language&country)
export const buildPath = (
  pathConfig: {
    pageId: string;
    params?: Record<string, string>;
    queryParams?: Record<string, string>;
  } & (
    | {
        locale: string;
      }
    | {
        language: Language;
        country: Country;
      }
  )
) => {
  const { pageId, params = {}, queryParams = {} } = pathConfig;
  const locale =
    'locale' in pathConfig
      ? pathConfig.locale
      : buildPathPrefix({ country: pathConfig.country, language: pathConfig.language });

  const pathWithParams = Object.keys(params).reduce(
    (acc, paramKey) => acc.replace(`:${paramKey}`, params[paramKey]),
    `${buildPathForLocale({ locale, pageId })}`
  );

  const queryParamsString = Object.keys(queryParams)
    .map(queryParam => `${queryParam}=${queryParams[queryParam]}`)
    .join('&');

  if (queryParamsString) {
    return `${pathWithParams}?${queryParamsString}`;
  }

  return pathWithParams;
};

export const buildCategoryPath = ({ locale, category }) => {
  return buildPath({
    locale,
    pageId: Paths.PCP,
    params: { category: CategoryPathsConfig[category]?.paths[locale] || '' },
  });
};

export const buildPlanPath = ({ locale, vib, category }) => {
  const categoryPath = CategoryPathsConfig[category]?.paths[locale];

  if (!categoryPath) {
    return null;
  }

  return buildPath({
    locale,
    pageId: Paths.PDP,
    params: {
      category: categoryPath,
      vib,
    },
  });
};

export const buildOfferPath = ({ locale, contentSlug, category }) => {
  const categoryPath = CategoryPathsConfig[category]?.paths[locale];

  if (!categoryPath) {
    return null;
  }

  return buildPath({
    locale,
    pageId: Paths.PDP,
    params: {
      category: categoryPath,
      vib: contentSlug,
    },
  });
};

export const buildLocalizedPath = ({ pageId, country, language, vib, category }) => {
  const locale = buildPathPrefix({ country, language });
  switch (pageId) {
    case Paths.PCP: {
      return buildCategoryPath({ locale, category });
    }
    case Paths.PDP: {
      return buildPlanPath({ locale, category, vib });
    }
    default: {
      return buildPath({ pageId, locale });
    }
  }
};

const addPathPrefixToI18nPath = ({ lang, cc, path }) => {
  return `/${cc.toLowerCase()}-${lang}${path !== '/' ? path : ''}`;
};

export const getI18nPathsWithPrefixesForEnabledLangsAndCountries = pathConfig => {
  const pathLocale = Object.keys(pathConfig);

  return pathLocale.map(locale => {
    // can't import because of nextjs - needs to move splitLocale to other folder
    const [country, language] = locale.split('-');

    return {
      url: addPathPrefixToI18nPath({ lang: language, cc: country, path: pathConfig[locale] }),
      lang: language,
      cc: country.toUpperCase(),
    };
  });
};

export const preparePathWithParams = (path, param) => {
  let modifiedPath = path;
  Object.entries(param).forEach(([key, value]) => {
    modifiedPath = modifiedPath.replace(`/:${key}`, `/${value}`);
  });

  return modifiedPath;
};

export const getHrefFromStoryblok = ({ locale, link, pageId }: { locale: string; link: string; pageId: string }) => {
  if (link) {
    return link;
  }

  return pageId ? buildPath({ locale, pageId }) : '';
};
