import { useLogger } from '@hooks/useLogger';

import { useCookies } from 'react-cookie';
import { useQuery } from 'react-query';

import { useUserContext } from '@context/user';
import { useContent } from '@hooks/useContent';
import { useHttpService } from '@hooks/useHttpService';
import { useJwt } from '@hooks/user/useJwt';
import { useUserLogoutMutation } from '@hooks/user/useUserLogoutMutation';
import { useRedirect } from '@hooks/useRedirect';
import { addCMSFieldsToSubscriptions } from '@utils/add-cms-fields-to-subscriptions';
import { Paths } from '@utils/paths/paths';

import { useLocale } from '../useLocale';

import { getCurrentUser } from '@services/http/user/get-current-user';
import { JWT_STORAGE_KEY, REFRESH_TOKEN_STORAGE_KEY } from 'constants/localStorage';

export const userQueryId = 'userQuery';

export const useUser = ({ redirectOnUnauthorized, refetchOnMount = false } = {}) => {
  const { logger } = useLogger({ context: 'useUser' });
  const httpService = useHttpService();
  const jwtToken = useJwt();
  const logout = useUserLogoutMutation();
  const redirect = useRedirect();
  const { language, country } = useLocale();
  const { data: plansContent } = useContent({
    locale: `${country}-${language}`,
    name: `plans-${country}`,
  });
  const { useUserEnabled } = useUserContext();
  const [, , removeCookies] = useCookies([JWT_STORAGE_KEY, REFRESH_TOKEN_STORAGE_KEY]);

  if (!jwtToken && redirectOnUnauthorized) {
    redirect({ pageId: Paths.ProductsOverview });
  }

  return useQuery(
    userQueryId,
    async () => {
      logger.info({ message: 'Gettting user data' });
      const { data } = await getCurrentUser(httpService, true);
      if (country !== data.country) {
        // TODO: Remove after testing
        logger.info({ message: 'User country does not match. Logging out' });
        // TODO: next two lines should be moved to some logout utility function as it is used in many places
        removeCookies(JWT_STORAGE_KEY, { path: '/', sameSite: 'strict', secure: true });
        removeCookies(REFRESH_TOKEN_STORAGE_KEY, {
          path: '/',
          sameSite: 'strict',
          secure: true,
        });

        return undefined;
      }

      logger.info({ message: 'Logged in' });

      return data;
    },
    {
      enabled: !!jwtToken && useUserEnabled && !!country && !!language,
      refetchOnMount,
      refetchOnWindowFocus: false,
      retry: 0,
      select: data => addCMSFieldsToSubscriptions(data, plansContent),
      onError: async error => {
        logger.error({ message: 'Error in useUser', params: error });

        if (redirectOnUnauthorized) {
          redirect({ pageId: Paths.ProductsOverview });
        }

        if (!redirectOnUnauthorized && error.code !== 'ECONNABORTED') {
          await logout.mutateAsync();
        }
      },
    }
  );
};
