const config = require('../../services/config/client');
const { Locales } = require('./locale');

const Category = {
  WashingMachine: 'washer',
  Fridge: 'fridge',
  Dryer: 'dryer',
  Freezer: 'freezer',
  Dishwasher: 'dishwasher',
  WasherDryer: 'washer-dryer',
  VacuumCleaner: 'vacuum-cleaner',
  KitchenMachine: 'kitchen-machine',
  Smartgrow: 'smartgrow',
  CoffeeMachine: 'coffee-machine',
  FreestandingStove: 'freestanding-stove',
  DemandTest: 'demand-test',
  Oven: 'oven',
  CleaningTool: 'cleaning-tool',
  GardenTool: 'garden-tool',
  PowerTool: 'power-tool',
};

const ProductionAvailableCategories = {
  nl: [
    Category.Dishwasher,
    Category.Dryer,
    Category.Freezer,
    Category.Fridge,
    Category.FridgeTest,
    Category.WasherDryer,
    Category.WashingMachine,
    Category.WashingMachineCopy,
    Category.KitchenMachine,
    Category.VacuumCleaner,
    Category.CoffeeMachine,
    Category.FreestandingStove,
    Category.DemandTest,
    Category.Oven,
    Category.CleaningTool,
    Category.GardenTool,
    Category.PowerTool,
  ],
  de: [
    Category.Dishwasher,
    Category.Dryer,
    Category.Freezer,
    Category.Fridge,
    Category.WasherDryer,
    Category.WashingMachine,
    Category.WashingMachineCopy,
    Category.KitchenMachine,
    Category.VacuumCleaner,
    Category.CoffeeMachine,
    Category.FreestandingStove,
    Category.DemandTest,
    Category.Oven,
    Category.CleaningTool,
    Category.GardenTool,
    Category.PowerTool,
  ],
};

const DevAvailableCategories = {
  nl: [...ProductionAvailableCategories.nl],
  de: [...ProductionAvailableCategories.de],
};

const CategoriesAvailablePerCountry =
  config.environmentName === 'aws-prod' ? ProductionAvailableCategories : DevAvailableCategories;

const CategoryPathsConfig = {
  [Category.WashingMachine]: {
    paths: {
      [Locales.nl.nl]: 'wasmachines',
      [Locales.nl.en]: 'washers',
      [Locales.de.de]: 'waschmaschinen',
    },
  },
  [Category.Fridge]: {
    paths: {
      [Locales.nl.nl]: 'koelkasten',
      [Locales.nl.en]: 'fridges',
      [Locales.de.de]: 'kuehlschraenke',
    },
  },
  [Category.Dryer]: {
    paths: {
      [Locales.nl.nl]: 'drogers',
      [Locales.nl.en]: 'dryers',
      [Locales.de.de]: 'trockner',
    },
  },
  [Category.Freezer]: {
    paths: {
      [Locales.nl.nl]: 'vriezers',
      [Locales.nl.en]: 'freezers',
      [Locales.de.de]: 'gefrierschraenke',
    },
  },
  [Category.WasherDryer]: {
    paths: {
      [Locales.nl.nl]: 'was-droogcombinaties',
      [Locales.nl.en]: 'washer-dryers',
      [Locales.de.de]: 'waschtrockner',
    },
  },
  [Category.Dishwasher]: {
    paths: {
      [Locales.nl.nl]: 'vaatwassers',
      [Locales.nl.en]: 'dishwashers',
      [Locales.de.de]: 'spuelmaschinen',
    },
  },
  [Category.VacuumCleaner]: {
    paths: {
      [Locales.nl.nl]: 'stofzuigers',
      [Locales.nl.en]: 'vacuum-cleaners',
      [Locales.de.de]: 'staubsauger',
    },
  },
  [Category.KitchenMachine]: {
    paths: {
      [Locales.nl.nl]: 'keukenmachines',
      [Locales.nl.en]: 'kitchen-machines',
      [Locales.de.de]: 'kuchenmaschinen',
    },
  },
  [Category.Smartgrow]: {
    paths: {
      [Locales.nl.nl]: 'tuinieren',
      [Locales.nl.en]: 'smartgrow',
      [Locales.de.de]: 'smartgrow',
    },
  },
  [Category.CoffeeMachine]: {
    paths: {
      [Locales.nl.nl]: 'koffiemachines',
      [Locales.nl.en]: 'coffee-machines',
      [Locales.de.de]: 'kaffeemaschinen',
    },
  },
  [Category.FreestandingStove]: {
    paths: {
      [Locales.nl.nl]: 'freestanding-stove',
      [Locales.nl.en]: 'freestanding-stove',
      [Locales.de.de]: 'freestanding-stove',
    },
  },
  [Category.DemandTest]: {
    paths: {
      [Locales.nl.nl]: 'onze-selectie',
      [Locales.nl.en]: 'our-selection',
      [Locales.de.de]: 'unsere-auswahl',
    },
  },
  [Category.Oven]: {
    paths: {
      [Locales.nl.nl]: 'ovens',
      [Locales.nl.en]: 'ovens',
      [Locales.de.de]: 'backofen',
    },
  },
  [Category.CleaningTool]: {
    paths: {
      [Locales.nl.nl]: 'cleaning-tool',
      [Locales.nl.en]: 'cleaning-tool',
      [Locales.de.de]: 'reinigungsgerate',
    },
  },
  [Category.GardenTool]: {
    paths: {
      [Locales.nl.nl]: 'garden-tool',
      [Locales.nl.en]: 'garden-tool',
      [Locales.de.de]: 'gartengerate',
    },
  },
  [Category.PowerTool]: {
    paths: {
      [Locales.nl.nl]: 'power-tool',
      [Locales.nl.en]: 'power-tool',
      [Locales.de.de]: 'elektrowerkzeuge',
    },
  },
};

module.exports = {
  Category,
  CategoryPathsConfig,
  CategoriesAvailablePerCountry,
};
