const Locales = {
  nl: { nl: 'nl-nl', en: 'nl-en' },
  de: { de: 'de-de', en: 'de-en' },
};

const EnabledLocales = [Locales.nl.nl, Locales.nl.en, Locales.de.de];

const StoryblokLocales = [Locales.nl.nl, Locales.nl.en, Locales.de.de, Locales.de.en];

const DefaultLocale = 'de-en';

module.exports = {
  EnabledLocales,
  Locales,
  DefaultLocale,
  StoryblokLocales,
};
