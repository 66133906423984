import axios from 'axios';
import { useQuery } from 'react-query';

const CONTENT_QUERY_ID = 'CONTENT_QUERY_ID';

export const useContent = ({ name, locale, shouldReturnArray = false }) => {
  const isAnyValueUndefined = !locale || !name || name?.includes('undefined');

  return useQuery(
    [CONTENT_QUERY_ID, locale, name],
    async () => {
      const {
        data: { stories },
      } = await axios.get(`/api/storyblok/get-content?locale=${locale}&name=${encodeURIComponent(name)}`);

      return stories;
    },
    {
      enabled: !isAnyValueUndefined,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: 0,
      select: data => {
        if (shouldReturnArray) {
          return data;
        }

        return data.reduce((acc, current) => {
          acc[current.slug] = current.content;

          return acc;
        }, {});
      },
    }
  );
};
