const { Locales } = require('./locale');

const Cluster = {
  WashingAndDrying: 'washing-and-drying',
  CoffeeMachines: 'coffee-machines',
  VacuumCleaners: 'vacuum-cleaners',
  CoolingAndFreezing: 'cooling-and-freezing',
  CookingAndBaking: 'cooking-and-baking',
  Dishwashers: 'dishwashers',
  ToolsForHomeAndGarden: 'tools-for-home-and-garden',
};

const ClusterPathsConfig = {
  [Cluster.WashingAndDrying]: {
    paths: {
      [Locales.nl.nl]: '/wassen-en-drogen',
      [Locales.nl.en]: '/washing-and-drying',
      [Locales.de.de]: '/waschen-und-trocknen',
    },
  },
  [Cluster.CoolingAndFreezing]: {
    paths: {
      [Locales.nl.nl]: '/koelen-en-vriezen',
      [Locales.nl.en]: '/cooling-and-freezing',
      [Locales.de.de]: '/kuehlschraenke-und-gefrierschraenke',
    },
  },
  [Cluster.CoffeeMachines]: {
    paths: {
      [Locales.nl.nl]: '/koffiemachines',
      [Locales.nl.en]: '/coffee-machines',
      [Locales.de.de]: '/kaffeemaschinen',
    },
  },
  [Cluster.VacuumCleaners]: {
    paths: {
      [Locales.nl.nl]: '/stofzuigers',
      [Locales.nl.en]: '/vacuum-cleaners',
      [Locales.de.de]: '/staubsauger',
    },
  },
  [Cluster.CookingAndBaking]: {
    paths: {
      [Locales.nl.nl]: '/koken-en-bakken',
      [Locales.nl.en]: '/cooking-and-baking',
      [Locales.de.de]: '/kochen-und-backen',
    },
  },
  [Cluster.Dishwashers]: {
    paths: {
      [Locales.nl.nl]: '/vaatwassers',
      [Locales.nl.en]: '/dishwashers',
      [Locales.de.de]: '/geschirrspuler',
    },
  },
  [Cluster.ToolsForHomeAndGarden]: {
    paths: {
      [Locales.de.de]: '/werkzeuge-fuer-heim-and-garten',
    },
  },
};

module.exports = {
  Cluster,
  ClusterPathsConfig,
};
