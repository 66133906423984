import React, { createContext, useMemo, useContext } from 'react';

import { splitLocale } from '@services/locale-config';

export const ContentContext = createContext();

export const ContentContextProvider = ({ children, context }) => {
  const { country, language } = splitLocale(context.locale);

  return (
    <ContentContext.Provider
      value={useMemo(
        () => ({
          ...context,
          country,
          language,
        }),
        [context, country, language]
      )}
    >
      {children}
    </ContentContext.Provider>
  );
};

export const useContentContext = () => {
  return useContext(ContentContext);
};
