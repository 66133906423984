module.exports = {
  show(message, type, position) {
    typeof window !== 'undefined' &&
      localStorage &&
      localStorage.setItem(
        'notifications',
        JSON.stringify([
          {
            message,
            type,
          },
        ])
      );
  },
};
