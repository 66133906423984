module.exports = {
  env: process.env.NODE_ENV,
  appUrl: process.env.APP_URL,
  backendUrl: process.env.BACKEND_URL || 'http://localhost:8080',
  environmentName: process.env.ENVIRONMENT_NAME || 'local',
  storyblok: {
    accessToken: 'DcAKePWujqlekaLXy5I52Qtt',
  },
  storyblokManagementApi: {
    oauthToken: process.env.STORYBLOK_OAUTH_TOKEN,
  },
  cache: {
    redisUrl: process.env.REDIS_URL,
  },
  freshChat: {
    token: '77fe5a23-bc81-42d6-be7f-045d04a6cbab',
  },
};
