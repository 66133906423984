import getConfig from 'next/config';
const publicRuntimeConfig = getConfig()?.publicRuntimeConfig;

/**
 * Usage: Please remember to use this inside a component function.
 */
const getRuntimeConfig = key => {
  if (!publicRuntimeConfig) {
    return { BACKEND_URL: 'http://localhost' }[key];
  }

  const publicRuntime = publicRuntimeConfig[key] && atob(publicRuntimeConfig[key]);

  return publicRuntime || process.env[key];
};

export default getRuntimeConfig;
