import { datadogLogs } from '@datadog/browser-logs';

import getRuntimeConfig from '@utils/getRuntimeConfig';

const isProd = getRuntimeConfig('ENVIRONMENT_NAME') === 'aws-prod';

interface LogContent {
  message: string,
  params?: any,
}

type LogType = 'Info' | 'Warn' | 'Error'

export default class Logger {
  private context: string | null;
  private defaultParams = {
    env: getRuntimeConfig('ENVIRONMENT_NAME'),
  };

  constructor(context?: string) {
    this.setContext(context);
  }

  setContext(context: string) {
    this.context = context;
  }

  init() {
    this.checkIsEnvExists();

    if (!isProd) {
      console.log('Logger initialization');
    }

    datadogLogs.init({
      clientToken: getRuntimeConfig('DATADOG_CLIENT_TOKEN'),
      site: getRuntimeConfig('DATADOG_SITE'),
      forwardErrorsToLogs: false,
      // forwardConsoleLogs: ['error'], // available: "log" "debug" "info" "warn" "error"
      // forwardReports: ['intervention'], // https://developer.mozilla.org/en-US/docs/Web/API/Reporting_API, available: "intervention" "deprecation" "csp_violation"
      sessionSampleRate: 100,
      env: getRuntimeConfig('ENVIRONMENT_NAME'),
      beforeSend: log => {
        try {
          const parsedLogMessage = JSON.parse(log.message);

          if (parsedLogMessage.config) {
            // Remove sensitive data
            parsedLogMessage.config = {};
            log.message = JSON.stringify(parsedLogMessage);
          }
        } catch (e) {
        } finally {
          return true;
        }
      },
    });

    datadogLogs.setGlobalContext({ env: getRuntimeConfig('ENVIRONMENT_NAME') });
  }

  info({ message, params }: LogContent) {
    this.logHandler('Info', message, params)
  }

  error({ message, params }: LogContent) {
    this.logHandler('Error', message, params)
  }

  warn({ message, params }: LogContent) {
    this.logHandler('Warn', message, params)
  }

  private logHandler( type: LogType = 'Info', message:string, params:any) {
    this.handleConsoleLog({ message, params }, type);
    if (typeof document !== "undefined") {
      datadogLogs.logger.info(message, {
        severity: type,
        ...this.defaultParams,
        ...{ ...params, context: this.context },
      });
    }
  }

  private handleConsoleLog(log: LogContent, type: LogType = 'Info') {
    if (!isProd) {
      console[type.toLowerCase()](log.message, log.params);
    }
  }

  private checkIsEnvExists() {
    if (!getRuntimeConfig('DATADOG_CLIENT_TOKEN')) {
      return;
    }
  }
}
