import { useCookies } from 'react-cookie';
import { useMutation, useQueryClient } from 'react-query';

import { useHttpService } from '@hooks/useHttpService';
import { useLogger } from '@hooks/useLogger';

import { userQueryId } from './useUser';

import { JWT_STORAGE_KEY, REFRESH_TOKEN_STORAGE_KEY } from 'constants/localStorage';

export const useUserLogoutMutation = () => {
  const { logger } = useLogger({ context: 'useUserLogoutMutation' });
  const queryClient = useQueryClient();
  const httpService = useHttpService();
  const [, , removeCookies] = useCookies([JWT_STORAGE_KEY, REFRESH_TOKEN_STORAGE_KEY]);

  return useMutation(async () => {
    queryClient.setQueryData(userQueryId, undefined);
    localStorage.removeItem(JWT_STORAGE_KEY);
    localStorage.removeItem(REFRESH_TOKEN_STORAGE_KEY);

    removeCookies(JWT_STORAGE_KEY, { path: '/', sameSite: 'strict', secure: true });
    removeCookies(REFRESH_TOKEN_STORAGE_KEY, { path: '/', sameSite: 'strict', secure: true });
    try {
      const refreshToken = localStorage.getItem(REFRESH_TOKEN_STORAGE_KEY);
      if (refreshToken) {
        await httpService.post('/auth/logout', { refreshToken });
      }
    } catch (error) {
      logger.error({
        message: 'There is a problem with user logout',
        params: { errorMessage: error?.message },
      });
    }
  });
};
