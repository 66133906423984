import { useRouter } from 'next/router';
import { toast } from 'react-toastify';
import { StoryblokRichtext } from 'storyblok-rich-text-react-renderer';

import { useLocale } from '@hooks/useLocale';
import notifications from '@utils/notifications';
import { buildPathForLocale } from '@utils/paths/build-paths';

import { usePhrases } from './context/usePhrases';

export const useRedirect = () => {
  const { locale } = useLocale();
  const notificationsPhrases = usePhrases({ name: 'notifications', rawObject: true });
  const router = useRouter();
  const redirect = ({
    url,
    pageId,
    notificationPhraseKey,
    notificationType = toast.TYPE.WARNING,
    notificationPosition = toast.POSITION.TOP_CENTER,
    notificationTranslatedMessage = '',
  }: {
    url?: string;
    pageId?: string;
    notificationPhraseKey?: string;
    notificationType?: string;
    notificationPosition?: string;
    notificationTranslatedMessage?: string | StoryblokRichtext;
  }) => {
    const notificationMessage = notificationTranslatedMessage
      ? notificationTranslatedMessage
      : notificationsPhrases?.[notificationPhraseKey]?.value;

    if (notificationMessage) {
      notifications.show(notificationMessage, notificationType, notificationPosition);
    }

    router.push(url || buildPathForLocale({ pageId, locale }));
  };

  return redirect;
};
