import React from 'react';

import { ToastContainer as LibToastContainer } from 'react-toastify';

export const ToastMessage = props => {
  // cannot wrap styles with wrapper due to animations not working, see _toastify for styles
  return (
    <LibToastContainer
      position="top-center"
      autoClose={5000}
      className="toast-container"
      toastClassName="bm-toast"
      {...props}
    />
  );
};
