import React, { createContext, useContext, useState, useEffect } from 'react';

import { datadogRum } from '@datadog/browser-rum';

import { useLogger } from '@hooks/useLogger';
import { useUser } from '@hooks/user/useUser';
import { useUserUpdateCookieConsentMutation } from '@hooks/user/useUserUpdateCookieConsentMutation';

declare global {
  interface Window {
    Cookiebot?: {
      consent: {
        marketing: boolean;
        necessary: boolean;
        preferences: boolean;
        statistics: boolean;
      };
    };
  }
}
export const CookieContext = createContext({
  preferredCookies: { necessary: true, marketing: false, preferences: false, statistics: false },
});

export const extractPreferencesFromCookiebot = () => {
  if (typeof window === 'undefined' || typeof window.Cookiebot === 'undefined' || !window.Cookiebot.consent) {
    return { necessary: true, marketing: false, preferences: false, statistics: false };
  }

  return window.Cookiebot.consent;
};

export const CookieContextProvider = ({ children }) => {
  const [preferredCookies, setPreferredCookies] = useState(extractPreferencesFromCookiebot());
  const { data: user } = useUser();
  const { logger } = useLogger({ context: 'CookieContextProvider' });
  const userUpdateCookieConsent = useUserUpdateCookieConsentMutation();

  const updateCookiePreferences = () => {
    logger.info({ message: 'Updating cookie preferences', params: { userId: user?.id, preferredCookies } });
    setPreferredCookies(extractPreferencesFromCookiebot());
  };

  const updateDatadogOnCookieAccept = () => {
    logger.info({ message: 'Cookie accept', params: { userId: user?.id, preferredCookies } });
    const { statistics } = extractPreferencesFromCookiebot();
    if (statistics) {
      datadogRum.setTrackingConsent('granted');
    }
  };

  const updateDatadogOnCookieDecline = () => {
    logger.info({ message: 'Cookie decline', params: { userId: user?.id, preferredCookies } });
    const { statistics } = extractPreferencesFromCookiebot();
    if (!statistics) {
      datadogRum.setTrackingConsent('not-granted');
    }
  };

  useEffect(() => {
    // https://www.cookiebot.com/en/developer/ this event is fired when option is chosen and every time user with previously chosen option visit the site
    window.addEventListener('CookiebotOnConsentReady', updateCookiePreferences);

    window.addEventListener('CookiebotOnAccept', updateDatadogOnCookieAccept);
    window.addEventListener('CookiebotOnDecline', updateDatadogOnCookieAccept);

    return () => {
      window.removeEventListener('CookiebotOnConsentReady', updateCookiePreferences);
      window.removeEventListener('CookiebotOnAccept', updateDatadogOnCookieAccept);
      window.removeEventListener('CookiebotOnDecline', updateDatadogOnCookieDecline);
    };
  }, []);

  useEffect(() => {
    const updateUserMarketingConsent = async () => {
      try {
        logger.info({
          message: `Updating marketingCookieConsent ${preferredCookies.marketing}`,
          params: { userId: user.id, marketingCookieConsent: preferredCookies.marketing },
        });
        await userUpdateCookieConsent.mutateAsync();
      } catch (e) {
        logger.info({
          message: `Failed to update marketingCookieConsent ${preferredCookies.marketing}`,
          params: { userId: user.id, marketingCookieConsent: preferredCookies.marketing, errorMessage: e.message },
        });
      }
    };
    if (user && user.marketingCookieConsent && user.marketingCookieConsent !== preferredCookies.marketing) {
      updateUserMarketingConsent();
    }
  }, [user, preferredCookies.marketing]);

  return (
    <CookieContext.Provider
      value={{
        preferredCookies,
      }}
    >
      {children}
    </CookieContext.Provider>
  );
};

export const useCookieContext = () => {
  const cookieContext = useContext(CookieContext);

  if (!cookieContext) {
    throw new Error('You are out of the cookie context provider!');
  }

  return cookieContext;
};
