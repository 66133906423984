export const addCMSFieldsToSubscriptions = (data, plansContent) => {
  const subscriptions = data?.subscriptions?.map(subscription => {
    const sbPlan = plansContent?.[subscription?.plan?.bmId?.toLowerCase()];

    return {
      fields: {
        mainImage: sbPlan?.fullImage?.filename,
        fullName: sbPlan?.name,
      },
      ...subscription,
    };
  });

  return {
    ...data,
    subscriptions,
  };
};
