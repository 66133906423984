import React, { createContext, useContext, useState } from 'react';

export const UserContext = createContext();

export const UserContextProvider = ({ children }) => {
  const [useUserEnabled, setUseUserEnabled] = useState(true);

  return (
    <UserContext.Provider
      value={{
        useUserEnabled,
        setUseUserEnabled,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => {
  return useContext(UserContext);
};
