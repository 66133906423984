import { ClientHttpService } from '../client-http';

import { User } from 'types/user.types';

export const getCurrentUser = async (httpService: ClientHttpService, noCache = false) => {
  if (noCache) {
    return httpService.get<User>('/user/current-user', {
      headers: {
        'Cache-Control': 'no-cache',
      },
    });
  }

  return httpService.get<User>('/user/current-user');
};
