import { useCookies } from 'react-cookie';

import { JWT_STORAGE_KEY } from 'constants/localStorage';

export const useJwt = () => {
  const [cookies] = useCookies([JWT_STORAGE_KEY]);
  if (typeof window === 'undefined') {
    return;
  }

  const jwtKey = cookies[JWT_STORAGE_KEY];

  return jwtKey ?? undefined;
};
