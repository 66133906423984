// translations should be in phrase
// TODO let's try to get rid of this after removing country-selector page
const COUNTRIES = [
  {
    code: 'nl',
    name: 'Nederland',
    isEnabled: true,
    translations: {
      'nl-nl': 'Nederland',
      'nl-en': 'The Netherlands',
      'de-en': 'The Netherlands',
      'de-de': 'Niederlande',
    },
    langs: [
      {
        shortCode: 'nl',
        cfCode: 'nl',
        isEnabled: 1,
        isDefaultForCountry: 1,
        name: 'Nederlands',
        translations: {
          'nl-nl': 'Nederlands',
          'nl-en': 'Dutch',
        },
      },
      {
        shortCode: 'en',
        cfCode: 'en-US',
        isEnabled: 1,
        isDefaultForCountry: 0,
        name: 'English',
        translations: {
          'nl-nl': 'Engels',
          'nl-en': 'English',
        },
      },
    ],
  },
  {
    code: 'de',
    name: 'Deutschland',
    isEnabled: true,
    translations: {
      'nl-nl': 'Duitsland',
      'nl-en': 'Germany',
      'de-en': 'Germany',
      'de-de': 'Deutschland',
    },
    langs: [
      {
        shortCode: 'de',
        cfCode: 'de-DE',
        isEnabled: 1,
        isDefaultForCountry: 1,
        name: 'Deutsch',
        translations: {
          'de-en': 'German',
          'de-de': 'Deutsch',
        },
      },
      {
        shortCode: 'en',
        cfCode: 'de-DE',
        isEnabled: 0,
        isDefaultForCountry: 0,
        name: 'English',
        translations: {
          'de-en': 'English',
          'de-de': 'English',
        },
      },
    ],
  },
];

// footer, sitemap
export const getEnabledCountries = () => COUNTRIES.filter(c => c.isEnabled);

// footer, schema.org
export const getEnabledLanguages = country =>
  COUNTRIES.find(c => c.isEnabled && c.code === country).langs.filter(l => l.isEnabled);

export const splitLocale = locale => {
  const [country, language] = locale.split('-');

  return { country, language };
};

export const getAllLangsForCountry = countryCode => {
  const country = COUNTRIES.find(country => country.code === countryCode.toLowerCase());

  return country ? country.langs : [];
};

export const getEnabledLangsForCountry = countryCode => {
  return getAllLangsForCountry(countryCode.toLowerCase()).filter(lang => lang.isEnabled);
};
