import Axios, { AxiosRequestConfig } from 'axios';
import Cookies from 'universal-cookie';

import getRuntimeConfig from '@utils/getRuntimeConfig';

import { JWT_STORAGE_KEY, REFRESH_TOKEN_STORAGE_KEY } from '../../constants/localStorage';

import { Country, Language } from 'types/common.types';

const cookies = new Cookies();

const backendUrl = getRuntimeConfig('BACKEND_URL');

const axiosInstance = Axios.create();

type ClientHttpServiceConfig = AxiosRequestConfig & { bearerAuthorization?: boolean };

axiosInstance.interceptors.request.use((config: ClientHttpServiceConfig) => {
  const token = cookies.get(JWT_STORAGE_KEY);

  if (token && config.bearerAuthorization) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

axiosInstance.interceptors.response.use(
  res => res,
  async error => {
    const originalErrorToThrow = error.response ?? error;

    if (error.response?.status !== 401 || !error.config?.bearerAuthorization) {
      throw originalErrorToThrow;
    }

    try {
      // get old token
      const refreshToken = cookies.get(REFRESH_TOKEN_STORAGE_KEY);
      const authToken = cookies.get(JWT_STORAGE_KEY);

      // logout if jwt or refresh are invalid
      if (!authToken || !refreshToken) {
        throw originalErrorToThrow;
      }

      // get new
      const response = await Axios.post(
        `${backendUrl}/auth/refresh`,
        { refreshToken, authToken },
        { headers: error.config.headers }
      );

      cookies.set(JWT_STORAGE_KEY, response.data.authToken, { path: '/', sameSite: 'strict', secure: true });
      cookies.set(REFRESH_TOKEN_STORAGE_KEY, response.data.refreshToken, {
        path: '/',
        sameSite: 'strict',
        secure: true,
      });

      const countryHeaders = {
        'x-bm-country': error.config.headers['x-bm-country'],
        'x-bm-language': error.config.headers['x-bm-language'],
      };

      const retriedResponse = await Axios({
        ...error.config,
        headers: {
          ...countryHeaders,
          Authorization: `Bearer ${response.data.authToken}`,
        },
      });

      return retriedResponse;
    } catch (e) {
      throw originalErrorToThrow;
    }
  }
);

export class ClientHttpService {
  baseUrl: string;
  headers: { 'x-bm-country': Country; 'x-bm-language': Language };

  constructor({ language, country, baseUrl = backendUrl }) {
    if (language && country) {
      this.headers = {
        'x-bm-country': country,
        'x-bm-language': language,
      };
    }

    this.baseUrl = baseUrl;
  }

  getRequestConfig = (config = {} as ClientHttpServiceConfig) => ({
    ...config,
    bearerAuthorization: config.bearerAuthorization !== false,
    headers: {
      ...(this.headers || {}),
      ...(config.headers || {}),
    },
  });

  get<T = any>(url, config = {} as ClientHttpServiceConfig) {
    return axiosInstance.get<T>(`${this.baseUrl}${url}`, this.getRequestConfig(config));
  }

  post<T = any>(url, body, config = {} as ClientHttpServiceConfig) {
    return axiosInstance.post<T>(`${this.baseUrl}${url}`, body, this.getRequestConfig(config));
  }

  put<T = any>(url, body, config = {} as ClientHttpServiceConfig) {
    return axiosInstance.put<T>(`${this.baseUrl}${url}`, body, this.getRequestConfig(config));
  }

  patch<T = any>(url, body, config = {} as ClientHttpServiceConfig) {
    return axiosInstance.patch<T>(`${this.baseUrl}${url}`, body, this.getRequestConfig(config));
  }

  delete<T = any>(url, config = {} as ClientHttpServiceConfig) {
    return axiosInstance.delete<T>(`${this.baseUrl}${url}`, this.getRequestConfig(config));
  }
}
